import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Button from 'components/Button';
import { LazyCardGridProps } from './props';
import { GridContainer } from './styles';
import { CardLoadingSkeleton } from './utils';

export const LazyCardGrid = <T,>({
  cards,
  renderCard,
  cardsInRow,
  loadCount,
  totalAvailable,
  onLoadMore,
  gap,
  loadMoreButtonProps,
  loading,
  className,
  uniqueKey,
  resetTrigger,
}: LazyCardGridProps<T>) => {
  const [currentOffset, setCurrentOffset] = useState(0);
  const [allCards, setAllCards] = useState<T[]>([]);
  const [resetting, setResetting] = useState(false);
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (resetting) {
      setAllCards(cards);
      setResetting(false);
    } else if (cards.length > 0) {
      setAllCards((prevCards) => {
        if (uniqueKey) {
          const newCards = cards.filter(
            (card) =>
              !prevCards.some(
                (prevCard) => prevCard[uniqueKey as keyof T] === card[uniqueKey as keyof T],
              ),
          );
          return [...prevCards, ...newCards];
        }
        return [...prevCards, ...cards];
      });
    }
  }, [cards, uniqueKey, resetting]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else {
      if (resetTrigger !== undefined) {
        setCurrentOffset(0);
        setResetting(true);
        onLoadMore(0);
      }
    }
  }, [resetTrigger, onLoadMore]);

  const handleLoadMore = useCallback(() => {
    const newOffset = Math.min(currentOffset + loadCount, totalAvailable);
    setCurrentOffset(newOffset);
    onLoadMore(newOffset);
  }, [currentOffset, loadCount, totalAvailable, onLoadMore]);

  const loadingJsx: React.ReactNode[] = useMemo(
    () =>
      Array(loadCount)
        .fill(null)
        .map((_, index) => <CardLoadingSkeleton key={index} />),
    [loadCount],
  );

  return (
    <>
      <GridContainer gap={gap} cardsInRow={cardsInRow} className={className}>
        {allCards.map((card, index) => renderCard(card, index))}
        {loading && loadingJsx}
      </GridContainer>
      {allCards.length < totalAvailable && (
        <div className="mt-10 flex justify-center">
          <Button {...loadMoreButtonProps} onClick={handleLoadMore}>
            {loadMoreButtonProps.title}
          </Button>
        </div>
      )}
    </>
  );
};
