import { Control, FieldErrors, FieldValues } from 'react-hook-form';
import {
  GetCoachVideoAnalysisPendingQuery,
  GetPlayerVideoAnalysisPendingByPlayerIdQuery,
} from 'types/generated/client';
import { VideoAnalysisSkillsTypeEnum } from 'utils/shared/coachBuilder';

export type VideoAnalysisType =
  | GetCoachVideoAnalysisPendingQuery['videoAnalysis'][0]
  | GetPlayerVideoAnalysisPendingByPlayerIdQuery['videoAnalysis'][0];

export enum VideoAnalysisTabEnum {
  Pending = 'pending',
  Reviewed = 'reviewed',
}

export interface VideoAnalysisSwitcherProps {
  activeTab: VideoAnalysisTabEnum;
  setActiveTab: React.Dispatch<React.SetStateAction<VideoAnalysisTabEnum>>;
}

export interface AddSkillModalProps {
  isOpen: boolean;
  handleAddSkill: (skill: string) => void;
  handleClose: () => void;
}

export interface SkillsProps<T extends FieldValues> {
  control: Control<T>;
  errors: FieldErrors<T>;
  skillType: VideoAnalysisSkillsTypeEnum;
}

export interface AnalysisCardProps {
  analysisData: VideoAnalysisType;
  ctaText: string;
  isPlayerViewing?: boolean;
}
